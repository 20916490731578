import React from "react";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

const Container = styled(Box)`
    .whr-items {
        @media (min-width: 740px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: ${themeGet("space.5")}px;
        }
        .whr-item {
            @media (max-width: 767px) {
                margin-bottom: ${themeGet("space.5")}px;
            }
            .whr-title {
                margin-bottom: ${themeGet("space.1")}px;
                a {
                    font-weight: 800;
                    font-size: ${themeGet("fontSizes.2")}px;
                    @media ${themeGet("mediaQueries.lg")} {
                        font-size: ${themeGet("fontSizes.4")}px;
                    }
                }
            }
            .whr-info {
                color: ${themeGet("colors.grays.5")};
                li span {
                    font-weight: bold;
                }
            }
        }
    }
`;

function WorkableEmbed(props: BoxProps) {
    const ref = React.useRef(null);

    function init() {
        if (window.whr_embed && ref.current) {
            window.whr_embed(250289, { detail: "titles", base: "jobs", zoom: "country", grouping: "none" });
        } else {
            console.error("Workable SDK not loaded");
        }
    }

    React.useEffect(() => {
        if (!window.whr) {
            const script = document.createElement("script");
            script.src = "https://www.workable.com/assets/embed.js";
            document.body.appendChild(script);
            script.addEventListener("load", init);
        } else {
            init();
        }
    }, []);

    return <Container {...props} ref={ref} id="whr_embed_hook" />;
}

export default WorkableEmbed;
