import React, { useCallback, useEffect, useState } from "react";
import { Stories } from "../../interfaces/Story";
import PagingFilters, { Filter, useFilters } from "../../components/common/PagingFilters";
import { StoryCategory, StoryType } from "../../enums/Story";
import stories from "./stories";
import StoryFeature from "../../components/common/StoryFeature";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import PagingSimple from "../../components/common/PagingSimple";
import StoryHero from "../../components/common/StoryHero";

const yellowish = "#E5D51C";

/**
 * Utils
 */
function sortByDate(stories: Stories): Stories {
    return stories.sort((a, b) => (a.date <= b.date ? 1 : -1));
}

function filterByType(storyType: StoryType, stories: Stories): Stories {
    return stories.filter((story) => story.storyType === storyType);
}

function filterByCategory(storyCategories: Array<StoryCategory>, stories: Stories): Stories {
    return stories.filter((story) => storyCategories.indexOf(story.storyCategory) > -1);
}

interface StoryProps {
    baseUrl: string;
    imgParams: string;
}

export const Heroes = ({ baseUrl, imgParams, ...rest }: StoryProps) => {
    if (stories.length <= 0) return null;

    let storyHeroes = stories
        .filter((story) => story.storyType === StoryType.Hero)
        .map((story, i) => {
            return <StoryHero key={i} baseUrl={`${baseUrl}features`} imgParams={imgParams} {...story} {...rest} />;
        });

    return <>{storyHeroes}</>;
};

interface FeatureStoryProps extends StoryProps, BoxProps {
    initialFilters?: Filter[];
    showFilterButtons?: boolean;
    minColumnWidth?: string;
}

const defaultFilters = [
    { name: "All", isActive: true },
    { name: StoryCategory.Employee, isActive: true },
    { name: StoryCategory.Pro, isActive: true },
    { name: StoryCategory.TribeMember, isActive: true },
    { name: StoryCategory.Trainer, isActive: true },
    { name: StoryCategory.Sports, isActive: true },
    { name: StoryCategory.Lifestyle, isActive: true },
    { name: StoryCategory.Other, isActive: true },
];

export const Features = ({
    baseUrl,
    imgParams,
    initialFilters = defaultFilters,
    showFilterButtons = true,
    minColumnWidth = "360px",
    ...rest
}: FeatureStoryProps) => {
    const MAX_FEATURES_PER_PAGE = 9;
    const [featureStories, setFeatureStories] = useState<Stories>([]);
    const [filteredFeatureStories, setFilteredFeatureStories] = useState<Stories>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [categoryFilters, handleOnFiltersUpdate] = useFilters(initialFilters, () => {
        setCurrentPage(1);
    });

    const updateFilters = useCallback(
        (stories: any, filteredFeatures: any) => {
            setFeatureStories(sortByDate(stories));
            setFilteredFeatureStories(sortByDate(filteredFeatures));
        },
        [currentPage]
    );

    // Update category filters
    useEffect(() => {
        const features = filterByType(StoryType.Feature, stories);
        const activeCategories = categoryFilters.filter((c) => c.isActive).map((c) => c.name);
        const newFilteredFeatureStories = filterByCategory(activeCategories, features);
        updateFilters(features, newFilteredFeatureStories);
    }, [categoryFilters, currentPage]);

    const handleOnPageUpdate = (page: number) => {
        setCurrentPage(page);
    };

    let offset = (currentPage - 1) * MAX_FEATURES_PER_PAGE;

    if (featureStories.length <= 0) return null;

    let pagedFeatureStories = filteredFeatureStories.slice(offset, offset + MAX_FEATURES_PER_PAGE).map((story, i) => {
        return <StoryFeature key={i} baseUrl={`${baseUrl}features`} imgParams={imgParams} target="_blank" {...story} />;
    });

    return (
        <Box {...rest}>
            <Text as="h2" className="sr-only">
                Features
            </Text>

            {showFilterButtons && (
                <TwelveColumn mb={[4, 4, 4, 6]}>
                    <PagingFilters
                        p={1}
                        ml={[1, 3, 3, 3]}
                        mt={[2, 2, 2, 0]}
                        gridColumn={["1 / -1", "1 / span 10", "1 / span 10", "2 / span 10"]}
                        overflow={["scroll hidden", "initial"]}
                        onFilterUpdate={handleOnFiltersUpdate}
                        filters={categoryFilters}
                        activeColor={yellowish}
                        ariaDescription="Select buttons below to filter stories."
                    />
                </TwelveColumn>
            )}

            <TwelveColumn>
                <Box gridColumn={["1 / -1", "1 / -1", "1 / -1", "2 / span 10"]} minHeight="400px">
                    <Box
                        display={["block", "block", "block", "grid"]}
                        gridTemplateColumns={`repeat(auto-fill, minmax(${minColumnWidth}, 1fr))`}
                    >
                        {pagedFeatureStories.length ? (
                            pagedFeatureStories
                        ) : (
                            <Text color="white" role="alert" fontStyle="italic" ml={[2, 4, 2]}>
                                No stories exist with the current filter selection.
                            </Text>
                        )}
                    </Box>
                    <PagingSimple
                        highlightColor={yellowish}
                        textAlign="center"
                        currentPage={currentPage}
                        onClick={handleOnPageUpdate}
                        items={filteredFeatureStories}
                        maxPerPage={MAX_FEATURES_PER_PAGE}
                    />
                </Box>
            </TwelveColumn>
        </Box>
    );
};
